export const AMapConfig = {
  key: 'e135afc90666763b4c0fed15101ae02b',
  version: '2.0',
  plugins: [
    'AMap.ControlBar', 
    'AMap.ToolBar',
    'AMap.HawkEye',
    'AMap.Geolocation',
    'AMap.CitySearch',
    'AMap.CircleEditor',
    'AMap.MouseTool',
    'AMap.PlaceSearch',
    'AMap.AutoComplete',
    'AMap.DistrictSearch',
    'AMap.MoveAnimation',
    'AMap.MarkerCluster',
    'AMap.Geocoder',
    'AMap.RectangleEditor',
    'AMap.CircleEditor',
    'AMap.GraspRoad',
  ],
  // Loca:{                // 是否加载 Loca， 缺省不加载
  //   "version": '2.0.0'  // Loca 版本，缺省 1.3.2
  // },
}