<template>
  <div class='safetyDevice mainContainer safetyAlarmLogs'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name">
        <div>检索条件</div>
      </div>
      <el-form ref="searchForm" label-width="100px" inline @submit.native.prevent size="small" v-model="keyWord">
        <el-form-item label="设备名称:" prop="devName">
          <el-input type="text" class="w180" v-model="keyWord.boardName" placeholder="请输入设备名称" clearable
                    @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="设备标识:" prop="devNo">
          <el-input type="text" v-model="keyWord.boardId" class="w180" placeholder="请输入设备标识" clearable
                    @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-select v-model="keyWord.status" placeholder="选择状态" class="w100" @change="getList(1)" clearable>
            <el-option v-for="(item,index) in dicObj.statusData" :key="index" :label="item.dictName"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="">
      <div class="title_name">
        <div>设备总数：<span class="fs16 col00ffe">{{ total }}</span></div>
        <div>
          <el-button type="text" @click="openDio('add')"><i
              class="el-icon-circle-plus-outline"></i>添加
          </el-button>
          <el-button type="text" @click="openDio('setDataPoint')"><i
              class="el-icon-connection"></i>设置数据流向
          </el-button>
          <el-button type="text" class="error" @click="batchClick('del')"><i class="el-icon-delete"></i>批量删除
          </el-button>
        </div>
      </div>
      <div :style="{height:tableH+'px',overflow:'auto'}">
        <el-checkbox-group v-model="tableActive" @change="selectChange">
          <div class="pageList">
            <div class="item" v-for="(item,index) in tableData" :key="index">
              <el-checkbox :label="item.id"></el-checkbox>
              <div class="liStatus tr">
                <span title="在线状态" v-if="item.status!=null"
                      :class="['status',{'success':item.status=='1','info':item.status=='0'}]">
                  <span v-if="item.status=='1'">在线</span>
                  <span v-if="item.status=='0'">离线</span>
                </span>
                <!--                <span title="运行状态" v-if="item.runStatus!=null"
                                      :class="['status',{'success':item.runStatus=='1','warning':item.runStatus=='0'}]">
                                    <span v-if="item.runStatus=='1'">运行中</span>
                                    <span v-if="item.runStatus=='0'">停止</span>
                                  </span>-->
              </div>
              <div class="liCont">
                <el-image fit="contain" :src="getIcon(item.icon,item.id)"></el-image>
                <div class="liRight">
                  <p class="name" :title="item.boardName">{{ item.boardName }}</p>
<!--                  <p :title="item.boardId">标识：{{ item.boardId }}</p>-->
                  <p>场所：{{ item.homeName }}</p>
                  <p>IP：{{ item.boardIp }}</p>
                  <p>型号：{{item.productName}}</p>
                </div>
              </div>
              <div class="liTips" v-if="item.attributes &&item.attributes.length>0">
                <span v-for="(item2,index) in item.attributes"
                      v-if="item2.name=='电流'||item2.name=='电压'||item2.name=='设备功率'">
                  <template v-if="item2.name=='电流'"><em>I:</em>{{ item2.value }}{{ item2.unit }}</template>
                  <template v-if="item2.name=='电压'"><em>U:</em>{{ item2.value }}{{ item2.unit }}</template>
                  <template v-if="item2.name=='设备功率'"><em>P:</em>{{ item2.value }}{{ item2.unit }}</template>
                </span>
              </div>
              <div class="liBtn">
                <div class="btn el-icon-edit" v-if="isAuth('safe:safety:device:electric:edit')" title="编辑"
                     @click="openDio('edit',item)"></div>
                <div class="btn el-icon-s-order" v-if="isAuth('safe:safety:device:electric:detail')" title="详情"
                     @click="openDio('detail',item)"></div>
                <div class="btn el-icon-warning" v-if="isAuth('safe:safety:device:electric:rule')" title="告警规则"
                     @click="openDio('alarmRule',item)"></div>
                <div class="btn el-icon-delete" v-if="isAuth('safe:safety:device:electric:del')" title="删除"
                     @click="openDio('del',item)"></div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div class="pageDiv">
        <el-pagination
            :current-page="curr"
            :page-size="limits"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :page-sizes="[12, 20, 50, 100,200]"
            :total="total"
            @size-change="sizeChange"
            @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></add-edit>
    <!--详情-->
    <box-detail ref="detailDio" :dicObj="dicObj"></box-detail>
    <!--告警规则-->
    <alarm-rule ref="alarmRuleDio" :dicObj="dicObj"></alarm-rule>
    <!-- 数据流指向设置 -->
    <data-point-config ref="dataPointDio" :dicObj="dicObj" @netConfigSucc="getList()"></data-point-config>
  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import alarmRule from '../electric/alarm-rule'
import boxDetail from './box-detail'
import addEdit from './add-edit'
import dataPointConfig from '../gateway/data-point-config'
import {apiUrl, globalStr} from '@/assets/js/api';
import qs from 'qs'
let d_icon1 = require('@/assets/images/device-box.png');
var vm;
export default {
  props: [],
  name: 'safety-device-box',
  data() {
    return {
      //分页
      curr: 1,//当前页
      limits: 12,//每页容量
      total: 0,//数据总数
      tableH: '',//表格高度
      tableActive: [],//表格选中
      selectedDeviceIds: [],//表格选中deviceId
      tableData: [],
      isAdmin: false,//是否是超级管理员
      dicObj: [],
      keyWord: {
        boardName: '',
        boardId: '',
        status: ''
      }
    }
  },
  components: {
    addEdit,
    breadCrumb,dataPointConfig,
    boxDetail,
    alarmRule,
  },
  watch: {
    '$store.state.wsMsg': function (newVal, oldVal) {
      this.getSkMsg(newVal)
    },
    '$store.state.homeId': function (newVal) {
      if (newVal !== '0') {
        vm.$nextTick(() => {
          vm.getList()
        })
      }
    },
    '$store.state.csType': function (newVal) {
      if (newVal !== '0') {
        vm.$nextTick(() => {
          vm.getHome()
        })
      }
    },
  },
  async created() {
    vm = this
    vm.initHeight();
    await vm.getCurrDics();
    await vm.getList();
    await vm.getHome();
  },
  mounted() {
    window.onresize = () => {
      vm.initHeight()
    }
  },
  filters: {
    //状态过滤器
    statesFilter(val, type) {
      //在线状态
      if (type == '1') {
        let result = vm.dicObj.statusData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
    },
  },
  //方法
  methods: {
    //id转deviceId
    selectChange() {
      if (vm.tableActive && vm.tableActive.length > 0) {
        //从tableData中查找tableActive中选中的deviceId
        let deviceIdArr = [];
        vm.tableActive.forEach(id => {
          vm.tableData.filter(device => {
            if (device.id == id) {
              deviceIdArr.push(device.deviceId)
            }
          });
        });
        vm.selectedDeviceIds = deviceIdArr;
      }
    },
    //获取场所
    async getHome() {
      let dataObj = {
        'areaId': this.$store.state.csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if (res?.code == 200) {
        vm.dicObj.homeList = res.data
      }
    },
    //获取数据字典
    async getCurrDics() {
      let dataObj = ['DEVICE_STATUS',  'DEVICE_BOX_PRODUCT_TYPE']
      const res = await apiUrl.getDicts(dataObj);
      if (res?.code == 200) {
        vm.dicObj.statusData = res.data.DEVICE_STATUS || [];//设备状态
        vm.dicObj.productData = res.data.DEVICE_BOX_PRODUCT_TYPE || [];//厂商类型
      } else {
        vm.$message.error(res.message)
      }
    },
    //状态过滤器
    statesFilter(val, type) {
      //在线状态
      if (type == '1') {
        let result = vm.dicObj.statusData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //连接状态
      if (type == '2') {
        let result = vm.dicObj.connectData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //告警状态
      if (type == '3') {
        let result = vm.dicObj.alarmData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //合闸状态
      if (type == '4') {
        let result = vm.dicObj.tripData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //设备类型
      if (type == '5') {
        let result = vm.dicObj.typeDataElt.filter(item => item.dictValue == val)
        return result[0]?.dictName
      }
    },
    //获取ws数据
    getSkMsg(res) {
      //判断是设备状态通知(上、下线)
      if (res?.type == 'deviceStatus') {
        let str = vm.tableData?.find(item => item.deviceId == res.data.deviceData?.deviceId)
        if (str) {
          str.status = res.data.deviceData?.deviceStatus
          str.statusName = vm.statesFilter(str.status, '1');
        }
      }
    },
    getIcon(icon, id) {
      //请求压缩后的图片
      return d_icon1;
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'del') {
        vm.$confirm('确定删除该选项', '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              let arr = []
              if (item) {
                arr.push(item.id)
              }
              vm.deleteDevice(arr)
            })
            .catch(() => {
            })
      } else if (type == 'add' || type == 'edit') {
        vm.$refs.editDio.init(type, item);//新增或修改
      } else if (type == 'detail') {
        vm.$refs.detailDio.init(type, item);//详情
      } else if (type == 'alarmRule') {
        vm.$refs.alarmRuleDio.init(type, item);//告警规则
      }else if(type == 'setDataPoint'){
        //数据流指向 mqtt或UDP指向
        if (vm.selectedDeviceIds.length == 0) {
          vm.$message.warning('请勾选需要操作的网关')
          return;
        }
        vm.$refs.dataPointDio.init(type, vm.selectedDeviceIds);//数据流指向
      }
    },
    //判断数组中所有类型是否一致
    isTypeEqual(arr) {
      if (arr.length === 0) {
        return true; // 数组为空时返回true
      }
      for (let i = 0; i < arr.length; i++) {
        //判断设备类型不为网关
        if (arr[i].devType != '1') {
          return false; // 如果有一个对象的type不是0，就返回false
        }
      }
      return true; // 所有对象的type都相同，返回true
    },
    //批量操作
    batchClick(type) {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      let msg = ''
      if (type == 'del') msg = '是否确定要删除所选设备，删除后不可恢复？'
      if (type == 'close') {
        msg = '是否执行关闭指令，请谨慎操作？'
        let newArr = vm.tableData.filter(obj => vm.tableActive.includes(obj.id));
        if (vm.isTypeEqual(newArr) == false) {//判断设备类型
          vm.$message.warning('开合闸操作不能有网关设备')
          return
        }
      }
      if (type == 'open') {
        msg = '是否执行开启指令，请谨慎操作？'
        let newArr = vm.tableData.filter(obj => vm.tableActive.includes(obj.id));
        if (vm.isTypeEqual(newArr) == false) {//判断设备类型
          vm.$message.warning('开合闸操作不能有网关设备')
          return
        }
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type == 'del') {
          vm.deleteDevice(vm.tableActive);//删除
        }

      }).catch(() => {
      });
    },
    //删除
    async deleteDevice(ids) {
      const res = await apiUrl.deleteDeviceBox(ids);
      if (res?.code == 200) {
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.tableActive = []
        vm.selectedDeviceIds = []
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange(val) {
      vm.limits = val
      vm.getList(vm.curr, val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList(_curr, _limits) {
      vm.tableActive = []
      vm.selectedDeviceIds = []
      let dataObj = {
        'areaId': vm.$store.state.csType,
        'homeId': vm.$store.state.homeId,
        'pageNo': _curr ? _curr : vm.curr,
        'pageSize': _limits ? _limits : vm.limits,
        ...vm.keyWord
      }
      const res = await apiUrl.deviceBoxPage(dataObj)
      if (res?.code == 200) {
        vm.tableData = res.data.list;
        vm.total = parseInt(res.data.totalSize);
        vm.curr = _curr ? _curr : vm.curr;
      } else {
        vm.$message.error(res.message);
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 350
    }
  }
}
</script>
<style lang='scss'>
.safetyDevice {
  .el-tabs__content {
    padding: 10px 10px 0;
  }

  .el-checkbox-group {
    font-size: 14px;
  }

  .pageList {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: calc((100% - 45px) / 4);
      height: 149px;
      padding: 15px 10px;
      box-sizing: border-box;
      margin: 10px 7.5px;
      flex-shrink: 0;
      background: url('../../../../assets/images/ui/item_bg.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      &:nth-child(4n) {
        margin-right: 0px;
      }

      &:nth-child(4n-3) {
        margin-left: 0px;
      }

      .el-checkbox {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        z-index: 1;

        .el-checkbox__input {
          display: none;
        }

        &.is-checked {
          .el-checkbox__input {
            display: block;
          }
        }

        .el-checkbox__label {
          display: none;
        }

        .el-checkbox__inner {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          left: 10px;
          top: 10px;

          &::after {
            left: 7px;
            width: 6px;
            height: 12px;
          }
        }
      }

      .liStatus {
        height: 19px;

        .status {
          border-right: 1px solid #1d4975;
          padding: 0 5px;
          position: relative;
          z-index: 1;

          &:last-child {
            border-right: none;
          }
        }
      }

      .liBtn {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(33, 97, 155, .75);
        padding: 5px;
        box-sizing: border-box;
        display: none;
        align-items: center;
        justify-content: space-around;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 3;

        .btn {
          padding: 5px;
          cursor: pointer;
          font-size: 18px;
          color: #00c6ff;

          &.el-icon-delete {
            color: #F56C6C;
          }

          &:hover {
            opacity: .8;
          }
        }
      }

      &:hover .liBtn {
        display: flex;
      }

      .liTips {
        color: #00c6ff;
        margin-top: 5px;
        display: flex;
        justify-content: space-around;
      }

      .liCont {
        display: flex;

        .el-image {
          width: 35%;
          height: 80px;
          flex-shrink: 0;
          margin-right: 5px;
          position: relative;
          z-index: 0;
        }

        .liRight {
          width: calc(100% - 35% - 5px);
          flex-shrink: 0;

          i {
            font-size: 30px;
            color: #00c6ff;
            cursor: pointer;
          }

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
            line-height: 24px;

            &.name {
              font-size: 14px;
              color: #00c6ff;
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 0 3px 5px rgba(0, 0, 0, .15);
      }
    }
  }
}
</style>
